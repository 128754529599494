import React, { useState } from 'react';
import Modal from 'react-modal';
import SvcForm from '../SvcForm/ArSvcForm'
import addIcon from '../../add-icon.svg'
import { contact, socialAccounts } from '../../utils/config';

const SaveContactBtn = ({bizDetails}) => {
    const [isSvcModalOpen, setIsSvcModalOpen] = useState(false);
    const openSvcModal = () => {
        // setIsSvcModalOpen(true);
        window.open(socialAccounts.wsp)
    };
    const closeSvcModal = () => {
        setIsSvcModalOpen(false);
    };

    const saveContact = () => {
        // create a vcard file
        let vcard = "BEGIN:VCARD\nVERSION:4.0\nFN:" + contact.name + "\nTEL;TYPE=work,voice:" + contact.phone + "\nEMAIL:" + contact.email + "\nURL:" + contact.website + "\nEND:VCARD";

        let blob = new Blob([vcard], { type: "text/vcard" });
        let url = URL.createObjectURL(blob);

        const newLink = document.createElement('a');
        newLink.download = contact.name + ".vcf";
        newLink.textContent = contact.name;
        newLink.href = url;

        newLink.click();
    }

    return (
        <>
            <div onClick={saveContact} className="btn" >{bizDetails.saveBtnText} <img src={addIcon} width={20} alt="Add QB Media Contact" /></div>
            <div onClick={() => openSvcModal()} className="btn svc-btn" >{bizDetails.orderNowText}</div>
            <Modal isOpen={isSvcModalOpen} onRequestClose={closeSvcModal} className="modal svc-modal" overlayClassName="overlay">
                <SvcForm />
            </Modal>
        </>

    )
}

export default SaveContactBtn