export const endpoint = "https://qbmedia.b-cdn.net/qbmedia/"

export const bizDetails = {
    name: "Risto | ريـستـو",
    description: "ستيكات، برجر وأطباق لحم شهية",
    socialBtns : {
        menu: "المنيو",
        call: "اتصل",
        wsp: "واتساب",
        ig: "انستاغرام",
        fb: "فيسبوك",
        website: "الموقع",
        tiktok:"تيكتوك",
        mail: "مييل",
        review: "قييمني",
        meeting: "عين موعد"
    },
    saveBtnText: "ضيفني لجهات الاتصال",
    orderNowText: "أطلب وجبتك الأن"
}

export const bizDetails_He = {
    name: "Risto | ריסתו",
    description: "סטייקים, המבורגרים ומנות בשריות",
    socialBtns : {
        menu: "התפריט",
        call: "שיחה",
        wsp: "וואטספ",
        ig: "אינסטגרם",
        fb: "פייסבוק",
        website: "האתר",
        tiktok:"טיקטוק",
        mail: "מייל",
        review: "דרג אותנו",
        meeting: "פגישה"
    },
    saveBtnText: "הוסף אותנו לאנשי קשר",
    orderNowText: "הזמן שירות עכשיו"
}

export const bizDetails_En = {
    name: "Risto Steakhouse",
    description: "Steaks, Burgers and BBQ",
    socialBtns : {
        menu: "Menu",
        call: "Call Us",
        wsp: "Txt Us",
        ig: "Our IG",
        fb: "Our FB",
        website: "Website",
        tiktok:"Tiktok",
        mail: "Mail",
        review: "Rate Us",
        meeting: "Meet Us"
    },
    saveBtnText: "Add Us To Contacts",
    orderNowText: "Order a Service"
}

export const contact = {
    name: "Risto Steakhouse",
    phone: "97246064333",
    email: "info@risto.co.il",
    website: "https://risto.co.il/",
    ig:"risto.steakhouse",
    fb:"profile.php?id=61560767212586",
    menu:"https://menu.risto.co.il/",
    waze: "",
};

export const schedule = {
    link:"https://.com",
    title:"קביעת פגישה אונליין",
}

export const footer = {
    desclaimer: "برمجه وتصميم",
    copywrites: "كيوبي ميديا"
}

export const socialAccounts = {
    wsp:`https://api.whatsapp.com/send?phone=${contact.phone}`,
    ig:`http://instagram.com/${contact.ig}`,
    fb:`https://facebook.com/${contact.fb}`,
}